.musicPlay {
  color: white;
}
.musicImage {
  height: 45vh;
  width: 40%;
  display: flex;
  margin: 0 auto;
  object-fit: contain;
}
.musicBottomContainer {
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.dotSeparator {
  width: 5px;
  height: 5px;
  background-color: green;
  border-radius: 50%;
  margin-inline: 5px;
}
.music_info {
  text-align: center;
  padding-bottom: 30px;
}
.producerAndGenre {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.waveformContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  width: 90%;
  margin: 0 auto;
}
.waveform {
  height: 40px;
  width: 100%;
  overflow: hidden;
  margin-inline: 20px;
}

.playerControls {
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  padding-block: 2.5rem;
}
.playButton {
  transform: scale(2.5);
  background-color: white;
  color: black;
  border-radius: 5px;
  padding-bottom: 5px;
}

.skipPrevButton,
.skipNextButton,
.starButton {
  transform: scale(1.5);
}
@media only screen and (max-width: 500px) {
  .logoMusic {
    width: 120px;
    height: 30px;
  }
  .paraColor {
    font-size: 15px;
  }
  .mainNavbar {
    display: flex;
    flex-direction: row !important;
  }
  .modalStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px !important;
    height: 100px;
    background-color: white !important;
    padding: 5px;
    border-width: 0px;
    border-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  }
}

.audioStyle {
  width: 100%;
}
.emoji {
  font-size: 35px;
  cursor: pointer;
}

.emojiContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  justify-content: space-around;
}
.mainEmoji {
  display: contents;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.emojiStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 55px;
  border: 2px solid grey;
  border-radius: 10px;
  background-color: black;
}
.emojiStyleSub {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid grey;
  height: 65px;
  width: 65px;
  border-radius: 10px;
  background-color: black;
}
.emojiContain {
  display: contents;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.musicPlay {
  display: contents;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pointer {
  cursor: alias;
}
.emoji-input .emoji-icon {
  top: 0px !important;
}
.buttonsContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.yesStyle {
  width: 80px;
  height: 35px;
  text-align: center;
  background-color: #349ce4;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  cursor: pointer;
}
.noStyle {
  width: 80px;
  height: 35px;
  text-align: center;
  background-color: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  margin-right: 5px;
  cursor: pointer;
}
.modalStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 100px;
  background-color: white;
  padding: 5px;
  border-width: 0px;
  border-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.nextTextStyling {
  text-align: center;
}
.audioPlayerContainer {
  width: 60%;
}

@media only screen and (max-width: 500px) {
  .audioPlayerContainer {
    width: 80%;
  }
}
