.hr-style {
  display: none;
}
.comment-title {
  display: none;
}
.form {
  background-color: white;
}
.userLink {
  display: none;
}
.userActions .actionsBtn {
  display: none;
}
.replyBtn {
  display: none;
}
.infoStyle {
  display: none;
}
.no-comDiv {
  display: none;
}
.form .postComment {
  border-bottom: white;
}
.overlay {
  padding: 0px;
}
.halfDiv {
  display: none;
}
.form {
  padding: 0px;
  width: 300px;
  height: 33px;
  display: flex;
  align-items: center;
}

.form .postBtn {
  padding: 0px !important;
  display: flex;
  justify-content: center;
  height: 25px !important;
  width: 100px;
  font-size: 10px;
}

.form .userImg {
  display: none;
}
.emoji-picker-react .emoji button {
  width: 33px;
  background-color: white !important;
}
.emoji-picker-react div.skin-tones-list button {
  display: none;
}
.emoji-picker-react .emoji-categories button.active {
  background-color: white !important;
}

.emoji-picker-react .emoji-categories button {
  background-color: white !important;
}
