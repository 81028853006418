.arrow
{
    width:40px;
    height:40px;
    background-color: black;
}
.forgetLink {
    padding-right: 2px;
    text-decoration: none;
    padding-left: 5px;
    color: white;
    font-family: "Lato";
  }
  .backHeading
  {
    padding-top: 9px;
  }