body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.css-u6loev-MuiFormHelperText-root
 {
  color: red!important;
  background-color: black;
  line-height: 0%;
  margin-top: 0px !important;
}

input:-internal-autofill-selected 
{
  background-color: white !important;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root 
{
  border-radius: 25px !important;
}
.css-1ptx2yq-MuiInputBase-root-MuiInput-root:after{
  border-bottom: none !important;
}
.css-348fk2{
display: none;
}
.css-13sljp9{
  background-color: white;
  height: 34px;
  width: 208px;
  border-radius: 38px;
  display: flex;
  justify-content: center;
  padding-left: 12px;
}
.css-1xw0pi7-MuiButtonBase-root-MuiButton-root.Mui-disabled{
  background-color: #B1B1B1 !important;
  color: black !important;
}
.css-15odmpe.Mui-disabled{
  background-color: #B1B1B1 !important;
  color: black !important;
}