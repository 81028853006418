.mainContainerQuestion {
  padding-top: 40px;
}
.mediaStyle {
  color: white;
  padding-bottom: 10px;
  text-align: center;
}
.questionInput {
  width: 210px;
  height: 30px;
  border-radius: 25px;
  border: 1px solid black !important;
  padding-left: 10px;
}
.questionButton {
  display: flex;
  justify-content: center !important;
  padding-top: 20px;
  padding-bottom: 55px;
}
.inputRange {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  text-align: center;
}
.range {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
}
.textColor {
  color: white;
  padding-right: 30px;
  width: 80px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.rangeItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slider {
  width: 100%;
  color:blue;
}
@media only screen and (max-width: 375px) {
  .mediaStyle {
    font-size: 10x !important;
    width: 90%;
  }
}

.headingStyle {
  color: white;
  text-align: center;
  font-size: 24px;
  padding-bottom: 30px;
}

.css-19ygod6-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input.css-19ygod6-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input.css-19ygod6-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input {
  width: 210px;
  height: 25px;
  background-color: white;
  border-radius: 25px;
  color: grey;
  padding-left: 10px;
  font-size: 12px;
}
.css-1gtikml.css-1gtikml.css-1gtikml{
  width: 210px;
  height: 25px;
  background-color: white;
  border-radius: 25px;
  color: grey;
  padding-left: 10px;
  font-size: 12px;
}
.css-1g12qau-MuiSvgIcon-root-MuiNativeSelect-icon {
  color: black;
}
.css-1ptx2yq-MuiInputBase-root-MuiInput-root {
  width: 210px !important;
}
.css-1ptx2yq-MuiInputBase-root-MuiInput-root {
  display: none;
}
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root{
  display: none !important;
}
.genderLabel
{
  color: white;
}
.radioBox
{
  display: flex;
  flex-direction: row;
  width:210px;
}
.radioBoxGender
{
  display: flex;
  flex-direction: row;
  width:210px;
  padding-bottom: 10px;
}
.dropdown
{
  width:300px;
}
.lableStyleCollege
{
  width:238px ;
  color:white;
  padding-top: 5px;
}
.collegeLabel
{
  color:white;
  padding-top:5px;
  padding-bottom:5px;
}
.genreStyle{
  width: 220px;
  height: 32px;
  background-color: white;
  border-radius:  30px;
  padding-left: 5px;
  padding-right: 5px;
}
.datePickerStyle {
  width: 200px;
  height: 33px;
  border-radius: 25px;
  border: 1px solid black !important;
  padding-left: 10px;
  padding-right:10px
}
.dropdownStyle{
  width: 220px !important;
}